import React, { useState, useEffect, useRef, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Project from "../components/Project"
import { Context } from "../context/Context"
import styles from "../assets/css/index.module.scss"
import { useSwipeable } from "react-swipeable"
import { usePresence, motion, AnimatePresence } from "framer-motion"
import ReactScrollWheelHandler from "react-scroll-wheel-handler"

const IndexPage = props => {
  const [projects, setProjects] = useState([])
  const projectData = useStaticQuery(graphql`
    query {
      allStrapiProject(sort: { fields: strapiId, order: ASC }) {
        edges {
          node {
            title
            description
            strapiId
            titleImg {
              publicURL
            }
          }
        }
      }
    }
  `).allStrapiProject.edges

  let { state, dispatch } = useContext(Context)

  const [activeProject, setActiveProject] = useState(state.selectedProject)
  const [direction, setDirection] = useState("down")
  const [action, setAction] = useState("")
  const [animating, setAnimating] = useState(false)
  const [unmounting, setUnmounting] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const initialMount = useRef(true)

  useEffect(() => {
    if (initialMount.current) {
      if (activeProject !== 1) {
        setScrolled(true)
      }
      if (projectData[0].node.title !== "") {
        projectData.unshift({ node: { title: "", titleImg: "", strapiId: 0 } })
      }
      setProjects(projectData)
      initialMount.current = false
    } else if (action === "next") {
      if (!scrolled) {
        setScrolled(true)
      }
      setActiveProject(activeProject + 1)
      dispatch({ type: "setProject", data: state.selectedProject + 1 })
    } else if (action === "prev") {
      setActiveProject(activeProject - 1)
      dispatch({ type: "setProject", data: state.selectedProject - 1 })
    }
  }, [action])

  const switchProject = (e, device) => {
    if (e.deltaY > 0) {
      if (device === "desktop") {
        if (activeProject + 1 < projects.length) {
          setDirection("down")
          setAction("next")

          if (direction === "down" && action === "next") {
            setActiveProject(activeProject + 1)
          }
        }
      } else {
        if (activeProject + 1 < projects.length) {
          setDirection("up")
          setAction("next")
          if (direction === "up") {
            setActiveProject(activeProject + 1)
          }
        }
      }
    } else if (e.deltaY < 0) {
      if (device === "desktop") {
        if (activeProject - 1 > 0) {
          setDirection("up")
          setAction("prev")
          if (direction === "up" && action === "prev") {
            setActiveProject(activeProject - 1)
          }
        }
      } else {
        if (activeProject - 1 > 0) {
          setDirection("down")
          setAction("prev")
          if (direction === "down") {
            setActiveProject(activeProject - 1)
          }
        }
      }
    }
  }

  const onWheel = (e, device) => {
    if (!animating) {
      setAnimating(true)
      switchProject(e, device)
    }
  }
  const handlers = useSwipeable({
    onSwipedUp: e => {
      if(activeProject + 1 < projects.length){
      onWheel(e, "mobile")
    }
      },
    onSwipedDown: e => {
      if(activeProject - 1 > 0){
      onWheel(e, "mobile")}
      }
  })

  const [isPresent, safeToRemove] = usePresence()

  useEffect(() => {
    if (!isPresent) {
      setDirection("up")
      setUnmounting(true)
    }
  }, [isPresent])

  useEffect(() => {
    if (unmounting) {
      setActiveProject(0)
    }
  }, [unmounting])

  return (
    !initialMount.current && (
      <>
        <SEO title="Home" />
        <AnimatePresence exitBeforeEnter>
          <ReactScrollWheelHandler
            className={styles.sections}
            upHandler={e => {
              activeProject - 1 > 0 && onWheel(e, "desktop")
            }}
            downHandler={e => {
              activeProject + 1 < projects.length && onWheel(e, "desktop")
            }}
            timeout={1}
          >
            <div
              id="scrollWindow"
              key="projects"
              className={styles.sections}
              {...handlers}
            >
              <motion.div className={styles.projectsWrapper}>
                <Project
                  thumbnail={state.images.thumbnails[activeProject - 1]}
                  projects={projects}
                  direction={direction}
                  activeProject={activeProject}
                  setAnimating={setAnimating}
                  setDirection={setDirection}
                  safeToRemove={safeToRemove}
                  scrolled={scrolled}
                  unmounting={unmounting}
                />
              </motion.div>
            </div>
          </ReactScrollWheelHandler>
        </AnimatePresence>
      </>
    )
  )
}
export default IndexPage
