import React, { useState, useRef } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { Link } from "gatsby"
import { CgArrowLongRightL } from "react-icons/cg"

import styles from "../assets/css/project.module.scss"
import descStyles from "../assets/css/projectDesc.module.scss"

const Project = ({ projects, activeProject, ...props }) => {
  const { titleImg, strapiId, title } = projects[activeProject].node
  const [mounted, setMounted] = useState(false)
  const [stepCount, setStepCount] = useState(0)
  const initialMount = useRef(true)

  const variants = {
    overlay: {
      inactive: {
        y: "0%",
        transition: {
          type: "tween",
          duration: 0.35,
          ease: [0.6, 0.01, 0.7, 1],
        },
      },
      enter: direction => {
        return {
          y: direction === "up" ? "-100%" : "100%",
          transition: {
            type: "tween",
            duration: 0.35,
            ease: [0.6, 0.01, 0.7, 1],
          },
        }
      },
      leave: direction => {
        return {
          y: direction === "up" ? ["100%", "0%"] : ["-100%", "0%"],
          transition: {
            type: "tween",
            duration: 0.35,
            ease: [0.6, 0.01, 0.7, 1],
          },
        }
      },
    },
    img: {
      inactive: direction => {
        return {
          y: direction === "up" ? "10%" : "-10%",
          transition: {
            type: "tween",
            duration: 0.4,
            ease: [0.38, 0.24, 0.36, 1],
          },
        }
      },
      enter: direction => {
        return {
          y: "0%",
          transition: {
            type: "tween",
            duration: 0.4,
            ease: [0.38, 0.24, 0.36, 1],
          },
        }
      },
      leave: direction => {
        return {
          y: direction === "up" ? ["0%", "-10%"] : ["0%", "10%"],
          transition: {
            type: "tween",
            duration: 0.4,
            ease: [0.38, 0.24, 0.36, 1],
          },
        }
      },
    },
    title: {
      inactive: direction => {
        return {
          y: mounted ? (direction === "up" ? "100%" : "-100%") : "100%",
          transition: { duration: 0.2 },
        }
      },
      enter: {
        y: "0%",
        transition: { duration: 0.2, delay: mounted ? 0.45 : 0.6 },
      },
      leave: direction => {
        return {
          y: direction === "up" ? "-100%" : "100%",
          transition: { duration: 0.2 },
        }
      },
    },
    titleBg: {
      inactive: direction => {
        return {
          y: mounted ? (direction === "up" ? "100%" : "-100%") : "100%",
          transition: { duration: 0.2 },
        }
      },
      enter: {
        y: "0%",
        transition: { duration: 0.2, delay: mounted ? 0.3 : 0.45 },
      },
      leave: direction => {
        return {
          y: direction === "up" ? "-100%" : "100%",
          transition: { duration: 0.2, delay: 0.15 },
        }
      },
    },
    link: {
      inactive: direction => {
        return {
          y: mounted ? (direction === "up" ? "100%" : "-100%") : "100%",
          transition: { duration: 0.2 },
        }
      },
      enter: {
        y: "0%",
        transition: { duration: 0.2, delay: 0.75 },
      },
      leave: direction => {
        return {
          y: direction === "up" ? "-100%" : "100%",
          transition: {
            duration: 0.1,
            type: "tween",
            ease: "easeOut",
          },
        }
      },
    },
    scroll: {
      inactive: {
        y: "100%",
      },
      enter: {
        y: "0%",
        transition: {
          duration: 0.2,
          delay: 0.65,
          type: "tween",
          ease: "easeOut",
        },
      },
      leave: unmounting => {
        return {
          y: unmounting ? "-100%" : "100%",
        }
      },
    },
    projectCounter: {
      inactive: direction => {
        return {
          y: direction === "up" ? "100%" : "-100%",
        }
      },
      enter: {
        y: 0,
        transition: {
          duration: 0.2,
          type: "tween",
          ease: "easeOut",
        },
      },

      leave: direction => {
        return {
          y: direction === "up" ? "100%" : "-100%",
        }
      },
    },
  }

  const onAnimationStart = () => {
    setStepCount(stepCount + 1)
    props.setAnimating(true)
  }

  const onAnimationComplete = () => {
    if (stepCount === 3) {
      props.setAnimating(false)
      setStepCount(1)
      if (activeProject === 0) {
        initialMount.current = true
        props.safeToRemove()
      }
    } else {
      setStepCount(stepCount + 1)
    }
    if (initialMount.current) {
      props.setDirection("up")
      setMounted(true)
      initialMount.current = false
    }
  }

  return (
    <>
      <AnimatePresence custom={props.direction} exitBeforeEnter>
        <motion.div
          className={styles.wrapper}
          initial={mounted ? "inactive" : "enter"}
          animate={mounted ? "enter" : "inactive"}
          exit="leave"
          variants={variants}
          key={strapiId}
          custom={props.direction}
          onAnimationStart={onAnimationStart}
          onAnimationComplete={onAnimationComplete}
        >
          <motion.div
            className={styles.overlay}
            custom={props.direction}
            variants={variants.overlay}
          />
          <motion.div
            className={styles.imgWrapper}
            style={
              mounted && activeProject !== 0
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            {!initialMount.current && titleImg !== "" && (
              <motion.img
                src={props.thumbnail.src}
                variants={variants.img}
                custom={props.direction}
                initial="inactive"
                animate="enter"
                exit="leave"
              />
            )}
          </motion.div>
        </motion.div>
      </AnimatePresence>
      <div className={descStyles.wrapper}>
        <div style={{ overflow: "hidden" }}>
          <AnimatePresence custom={props.direction} exitBeforeEnter>
            <motion.div
              key={strapiId}
              custom={props.direction}
              variants={variants.titleBg}
              initial={"inactive"}
              animate={"enter"}
              exit={"leave"}
              style={{ overflow: "hidden", backgroundColor: "#111" }}
            >
              <Link
                to={`projects/${encodeURI(title)}`}
                className={descStyles.link}
                style={{ padding: 0 }}
              >
                <motion.div
                  variants={variants.title}
                  key={strapiId}
                  custom={props.direction}
                  initial={"inactive"}
                  animate={"enter"}
                  exit="leave"
                  className={descStyles.title}
                >
                  {title}
                </motion.div>
              </Link>
            </motion.div>
          </AnimatePresence>
        </div>
        <span className={descStyles.linkWrapper}>
          <AnimatePresence exitBeforeEnter>
            {titleImg !== "" && (
              <motion.div
                variants={variants.link}
                key={strapiId}
                custom={props.direction}
                initial={"inactive"}
                animate={"enter"}
                exit="leave"
              >
                <Link
                  className={descStyles.link}
                  to={`projects/${encodeURI(title)}`}
                >
                  view
                  <CgArrowLongRightL />
                </Link>
              </motion.div>
            )}
          </AnimatePresence>
        </span>
      </div>
      <div className={styles.scrollNote}>
        <div style={{ overflow: "hidden" }}>
          <AnimatePresence exitBeforeEnter custom={props.unmounting}>
            {!props.scrolled && !props.unmounting ? (
              <motion.div
                key="scroll"
                custom={props.unmounting}
                variants={variants.scroll}
                initial={"inactive"}
                animate={"enter"}
                exit="leave"
              >
                SCROLL TO SEE MORE
              </motion.div>
            ) : (
              activeProject !== 0 && (
                <motion.div
                  key="counter"
                  initial={"inactive"}
                  animate={"enter"}
                  exit={"leave"}
                  variants={variants.projectCounter}
                  custom={props.direction}
                >{`${activeProject}/${projects.length - 1}`}</motion.div>
              )
            )}
          </AnimatePresence>
        </div>
      </div>
    </>
  )
}

export default Project
